.toggleButtonStyle {
  width: 150px;
  border-radius: 7px;
  margin-right: 5px;
  margin-bottom: 5px;
}

div .calendar-component-container {
  /* border: solid red 1px; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  row-gap: 10px;
  column-gap: 20px;
  width: 100%;
}

div .calendar-month-picker-component-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  box-sizing: border-box;
  row-gap: 5px;
  column-gap: 5px;
  border: none;
  width: 100%;
}

div .calendar-day-picker-component-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  box-sizing: border-box;
  row-gap: 5px;
  column-gap: 5px;
  border: none;
  width: 100%;
}

div .calendar-time-slot-picker-component-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  box-sizing: border-box;
  row-gap: 5px;
  column-gap: 5px;
  border: none;
  width: 100%;
}

div .calendar-day-picker-component-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  box-sizing: border-box;
  row-gap: 5px;
  column-gap: 5px;
  border: none;
  width: 100%;
}

div .calendar-timeSlot-picker-component-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  box-sizing: border-box;
  row-gap: 5px;
  column-gap: 5px;
  border: none;
  width: 100%;
}

div .calendar-timeSlot-confirmation-picker-component-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  box-sizing: border-box;
  row-gap: 5px;
  column-gap: 5px;
  border: none;
  width: 100%;
}

div .calendar-topic-picker-component-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  box-sizing: border-box;
  row-gap: 5px;
  column-gap: 5px;
  border: none;
  width: 100%;
}

div .calendar-comment-picker-component-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  box-sizing: border-box;
  row-gap: 5px;
  column-gap: 5px;
  border: none;
  width: 100%;
}

div .calendar-fragment-header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  box-sizing: border-box;
  row-gap: 0px;
  column-gap: 5px;
  border: none;
  width: auto;
}