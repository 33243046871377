div .stack {
  display: flex;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  gap: 10px;
  border: none;
  height: 100%;
}

div .auto-height {
  height: auto;
}

div .horizontal {
  flex-flow: column nowrap;
}

div .vertical {
  flex-flow: row nowrap;
}

div .container {
  width: 100%;
  height: auto;
  overflow: auto;
  margin-top: 5px;
  box-sizing: border-box;
}

div .list-item {
  margin-top: 5px;
}

div .stretch {
  width: 100%;
}

div .stretch-height {
  height: 100%;
}

div .space-between {
  justify-content: space-between;
}

div .no-padding {
  padding: 0px;
}

div .no-margin {
  margin: 0px;
}

div .padding-top {
  padding-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}

div .padding-bottom {
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 10px;
}

div .padding-sides {
  padding-top: 0px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
}

div.light-border {
  border: 1px solid #0078d45b;
  box-sizing: border-box;
}

div .center-text {
  align-content: center;
}